import { debounce } from 'lodash-custom';
import cryptico from 'cryptico';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import config from 'app-customs/config/config';
import { DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS } from 'app-customs/config/dataConfig';

import * as Query from 'src/core/query/Query';
import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { get as getLabels } from 'src/core/Lang';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import {
  connect as wsConnect,
  disconnect as wsDisconnect,
  registerEvent as wsRegisterEvent,
} from 'src/core/realtime/RealTimeService';
import { getBindedActions } from 'src/store/bindedActions';
import isOnline from 'src/core/util/isOnline';
import showConfirmModal from 'src/core/util/showConfirmModal';
import { getAll, isFavorite, removeAll } from './Favorites';
import { getModeSynchFav } from 'src/core/favorites/SynchronizedFavoritesPersistence';

const LOG_PREF = '[SynchronizedFavoritesService] ';

export const STATUS = {
  DISABLED: 'disabled',
  USER_DISABLED: 'user',
  ONGOING: 'ongoing',
  FAILED: 'failed',
  SUCCESS: 'success',
};

export const ACTIONS = {
  CREATE: 'create',
  DELETE: 'delete',
};

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = (function() {
  const LOCALSTORAGE_KEY = 'synchronized-favorites';

  return {
    get() {
      const value = localStorage.getItem(LOCALSTORAGE_KEY);
      return value ? JSON.parse(value) : null;
    },
    set(value) {
      const toStore = typeof value === 'object' ? JSON.stringify(value) : value;
      localStorage.setItem(LOCALSTORAGE_KEY, toStore);
    },
  };
})();

export const codePersistence = (function() {
  const LOCALSTORAGE_KEY = 'synchronized-favorites-code';
  return {
    get() {
      return localStorage.getItem(LOCALSTORAGE_KEY);
    },
    set: (code) => {
      localStorage.setItem(LOCALSTORAGE_KEY, code);
    },
  };
})();

let currentStatus =
  isSynchFavPeeringMode() && codePersistence.get() ? STATUS.ONGOING : STATUS.DISABLED;
let syncFavs;

/**
 * Timestamps used to detect when the user toggled a favorite while the request was pending.
 * In this case, the request result is ignored.
 * */
let lastFavoriteToggle = 0;
let lastSynchroApiCall = 0;

const save = debounce(function _save() {
  persistence.set(syncFavs);
}, 1000);

let userId;
export function setUserId(value) {
  if (!value) {
    userId = value;
    stopOnlineIntervalPolling();
    !isSynchFavPeeringMode() && setStatus(STATUS.DISABLED);
    wsDisconnect();
  } else if (value !== userId) {
    // Because of login keep-alive, handle only when value is different
    userId = value;
    // synchronize(); // synchronize on WS connection (allows auto-synchronization on reconnection)
    wsConnect(`userId=${userId}`);
  }
}

let code;
export function setCode(value) {
  if (!value) {
    codePersistence.set('');
    stopOnlineIntervalPolling();
    setStatus(STATUS.DISABLED);
    wsDisconnect();
  } else if (value !== code) {
    code = value;
    codePersistence.set(value);
    wsConnect(`userId=${code}`);
  }
}

/**
 * Responsible for a synchronized favorite format
 * @param  {string} id
 * @param  {string} dataType
 * @param  {string} action
 * @param  {Date}   timestamp
 * @return {object}
 */
export const applySynchronizedFavFormat = (
  id,
  dataType,
  action = ACTIONS.CREATE,
  timestamp = new Date()
) => ({
  id,
  dataType,
  action,
  timestamp: timestamp.getTime(),
});

let onlineInterval = null;
const interval = 8000;
const startOnlineIntervalPolling = () => {
  if (!onlineInterval && (code || userId)) {
    onlineInterval = window.setInterval(() => {
      isOnline((online) => {
        if (online) {
          if (currentStatus === STATUS.FAILED || currentStatus === STATUS.DISABLED) {
            instantSynchronize();
          }
        } else if (currentStatus === STATUS.SUCCESS || currentStatus === STATUS.ONGOING) {
          setStatus(STATUS.FAILED);
        }
      });
    }, interval);
  }
};

const stopOnlineIntervalPolling = () => {
  if (onlineInterval) {
    window.clearInterval(onlineInterval);
    onlineInterval = null;
  }
};

/**
 * Call backend API to synchronize favorites with other devices
 * (debounced to batch requests)
 */
export const synchronize = debounce(instantSynchronize, config.SYNCHRONIZED_FAVORITES.DEBOUNCING);

/**
 * Synchronize instantly (useful on app startup or after login for instance)
 */
export function instantSynchronize() {
  startOnlineIntervalPolling();

  if (userId || code) {
    lastSynchroApiCall = new Date().getTime();

    const now = new Date().getTime();

    const _errorCb = (err) => {
      console.error(`${LOG_PREF}Failed to synchronize favorites`, err);

      setStatus(STATUS.FAILED);

      if (isSynchFavPeeringMode()) {
        getBindedActions().hideFavoritesCodeDialog();
        getBindedActions().showNotification({
          message: getLabels().synchroFavs.status[currentStatus],
          level: 'error',
        });
      }
    };

    try {
      const encryptedToken = cryptico.encrypt(
        `${userId ? 'user_id' : 'code'}:${userId || code}:${now}`,
        config.SYNCHRONIZED_FAVORITES.PUBLIC_KEY
      );

      if (encryptedToken.status !== 'success') throw new Error('encryption failed');

      // Call backend
      fetchHelper(
        `${config.SYNCHRONIZED_FAVORITES.URL}/sync`,
        {
          method: 'POST',
          headers: [HEADERS.JSON_CONTENT_TYPE],
          body: JSON.stringify({
            token: encryptedToken.cipher,
            favorites: syncFavs,
          }),
        },
        true, // isJson (auto parse response)

        _onSynchroResultReceived,

        _errorCb,

        false // showModalOnError
      );

      setStatus(STATUS.ONGOING);
    } catch (e) {
      _errorCb(e);
    }
  }
}

function _onSynchroResultReceived(result) {
  if (result && result.error && result.error === 'code') {
    setStatus(STATUS.FAILED);
    getBindedActions().showNotification({
      message: getLabels().synchroFavs.invalidCode,
      level: 'error',
    });
    getBindedActions().setCodeIdentification(null);
    getBindedActions().hideFavoritesCodeDialog();
    return;
  }

  if (lastSynchroApiCall < lastFavoriteToggle) {
    // If the user toggled a favorite since API call
    // then ignore the request result to avoid overriding local modification(s).
    return;
  }

  applySynchronizedFavorites(result);
}

function applySynchronizedFavorites(result) {
  // Note: risk of overriding user favorite actions
  // that occured while the request was pending
  syncFavs = result;
  save();

  // Update local state
  result.forEach(function(fav) {
    const isInLocalFavorites = isFavorite(fav.id, fav.dataType);

    if (
      (fav.action === ACTIONS.CREATE && !isInLocalFavorites) ||
      (fav.action === ACTIONS.DELETE && isInLocalFavorites)
    ) {
      getBindedActions().toggleFavorite(
        fav.id,
        fav.dataType,
        fav.action !== ACTIONS.CREATE,
        true // no sync, to avoid infinite loop (toggle fav -> sync -> toggle fav -> sync -> ...)
      );
    }
  });

  setStatus(STATUS.SUCCESS);
}

export const getCode = () => {
  const now = new Date().getTime();

  const _errorCb = (err) => {
    console.error(`${LOG_PREF}Failed to get favorite synchro code`, err);
    setStatus(STATUS.FAILED);
    getBindedActions().hideFavoritesCodeDialog();
    getBindedActions().showNotification({
      message: getLabels().synchroFavs.status[currentStatus],
      level: 'error',
    });
  };

  try {
    const encryptedToken = cryptico.encrypt(
      `get_code:${now}`,
      config.SYNCHRONIZED_FAVORITES.PUBLIC_KEY
    );
    if (encryptedToken.status !== 'success') throw new Error('encryption failed');

    fetchHelper(
      `${config.SYNCHRONIZED_FAVORITES.URL}/code`,
      {
        method: 'POST',
        headers: [HEADERS.JSON_CONTENT_TYPE],
        body: JSON.stringify({
          token: encryptedToken.cipher,
        }),
      },
      true, // isJson (auto parse response)

      _onCodeResultReceived,

      _errorCb,

      false // showModalOnError
    );
  } catch (e) {
    _errorCb(e);
  }
};

function _onCodeResultReceived(result) {
  if (result && result.code) {
    getBindedActions().setFavoritesCodeSyncStep('form');
    getBindedActions().setTemporaryCodeIdentification(result.code);
  } else {
    setStatus(STATUS.FAILED);
    getBindedActions().hideFavoritesCodeDialog();
    getBindedActions().showNotification({
      message: getLabels().synchroFavs.status[currentStatus],
      level: 'error',
    });
  }
}

/**
 * Load from localstorage, or initialize from current (non-synchronized) favorites
 */
export function init() {
  // Register websocket event
  wsRegisterEvent('synchronizedFavorites', function(data) {
    if (lastFavoriteToggle > new Date().getTime() - config.SYNCHRONIZED_FAVORITES.DEBOUNCING) {
      // if the user has local modifications not sent to the server
      // then skip incoming data.
      return;
    }

    applySynchronizedFavorites(data);
  });

  syncFavs = persistence.get();

  if (!syncFavs) {
    // Generate a 'synchronized' version of current favorites
    syncFavs = [];

    const currentFavorites = getAll();
    if (currentFavorites) {
      Object.keys(currentFavorites).forEach(function(dataType) {
        // Convert
        syncFavs = syncFavs.concat(
          currentFavorites[dataType].map((id) => applySynchronizedFavFormat(String(id), dataType))
        );
      });
    }
    save();
  }

  if (isSynchFavPeeringMode()) {
    // if code in storage
    const codeStorage = codePersistence.get();
    if (codeStorage) {
      getBindedActions().setCodeIdentification(codeStorage);
    }
  }

  synchronize();
}

/**
 * Look if item is already present
 *
 * @param  {string} id
 * @param  {string} dataType
 * @return {number}
 */
function getItemIndex(id, dataType) {
  for (let i = 0; i < syncFavs.length; i++) {
    if (syncFavs[i].id === id && syncFavs[i].dataType === dataType) {
      return i;
    }
  }
  return -1;
}

/**
 * Unitary set a favorite
 */
export function set(id, dataType, action, timestamp) {
  if (!id) {
    console.error(`${LOG_PREF}Missing favorite \`id\``);
    return;
  }
  if (!dataType) {
    console.error(`${LOG_PREF}Missing favorite \`dataType\``);
    return;
  }

  id = String(id);

  const item = applySynchronizedFavFormat(id, dataType, action, timestamp);
  const itemIndex = getItemIndex(id, dataType);

  if (itemIndex > -1) {
    // Replace existing item
    syncFavs[itemIndex] = item;
  } else {
    // Else add item to array
    syncFavs.push(item);
  }

  lastFavoriteToggle = new Date().getTime();

  save();

  if (currentStatus !== STATUS.DISABLED) {
    synchronize();
  }
}

/**
 * Define current status
 */
function setStatus(value) {
  currentStatus = value;
  getBindedActions().setFavoritesSynchronizationStatus(value);
}

export function isUnauthorizedMode() {
  return config.SYNCHRONIZED_FAVORITES.UNAUTHORIZED_MODE_ENABLED && !userId;
}

export function isSynchFavPeeringMode() {
  return getModeSynchFav() === 'peering';
}

export function isynchFavLoginMode() {
  return getModeSynchFav() === 'login' && userId;
}

/**
 * Handle when user clicks on toolbar icon
 */
export function handleClickOnIcon() {
  switch (currentStatus) {
    case STATUS.DISABLED:
      if (isSynchFavPeeringMode()) {
        getBindedActions().showFavoritesCodeDialog();
      } else if (!userId) {
        getBindedActions().navigate(LOGIN_PAGE_KEY);
      } else if (isynchFavLoginMode) {
        synchronize();
      }
      break;

    case STATUS.FAILED:
      if (isSynchFavPeeringMode()) {
        isOnline((online) => {
          if (online) {
            getBindedActions().showFavoritesCodeDialog();
          } else {
            getBindedActions().showNotification({
              message: getLabels().synchroFavs.status[currentStatus],
            });
          }
        });
      } else if (!userId) {
        getBindedActions().navigate(LOGIN_PAGE_KEY);
      } else {
        setStatus(STATUS.DISABLED);
      }
      break;
    case STATUS.ONGOING:
      break;
    case STATUS.SUCCESS:
      if (isSynchFavPeeringMode()) {
        showConfirmModal({
          text: getLabels().synchroFavs.comfirmDisableSynchro,
          yesBtnLabel: getLabels().common.ok,
          noBtnLabel: getLabels().common.cancel,
          anywayCb() {
            logoutConfirmModalDisplayed = false;
          },
          yesCb() {
            getBindedActions().setCodeIdentification(null);
            confirmKeepFavorites();
          },
          noCb: () => {},
        });
      } else if (isynchFavLoginMode) {
        getBindedActions().showNotification({
          message: getLabels().synchroFavs.status[currentStatus],
          level: 'error',
        });
      }
      break;

    default:
      console.error(`${LOG_PREF}Unexpected status: ${currentStatus}`);
  }
}

let logoutConfirmModalDisplayed = false;
let keepFavoritesConfirmModalDisplayed = false;

export function onLogout(cb) {
  if (logoutConfirmModalDisplayed) {
    // modal already displayed
    return;
  }
  logoutConfirmModalDisplayed = true;

  showConfirmModal({
    text: getLabels().synchroFavs.comfirmDisableSynchro,
    yesBtnLabel: getLabels().common.ok,
    noBtnLabel: getLabels().common.cancel,
    anywayCb() {
      logoutConfirmModalDisplayed = false;
    },
    yesCb() {
      if (cb && typeof cb === 'function') {
        cb();
      }
      confirmKeepFavorites();
    },
    noCb: () => {},
  });
}

function confirmKeepFavorites() {
  if (keepFavoritesConfirmModalDisplayed) {
    return;
  }

  let notice = getLabels().synchroFavs.logoutConfirm;
  if (isSynchFavPeeringMode()) {
    notice = getLabels().synchroFavs.logoutConfirmUnauthorizedMode;
  }

  showConfirmModal({
    text: notice,
    yesBtnLabel: getLabels().common.keep,
    noBtnLabel: getLabels().common.delete,
    anywayCb() {
      keepFavoritesConfirmModalDisplayed = false;
    },
    noCb() {
      removeAllLocalFavorites();
    },
  });
}

function removeAllLocalFavorites() {
  // delete all local favorites
  syncFavs = [];
  save();

  // Remove all favorites (genuine non-sync format)
  removeAll();

  getBindedActions().allFavoritesDeleted();
}

/**
 * Get appropriate icon depending on `status`
 * @see SynchronizedFavoritesService.STATUS
 *
 * @param  {string} status
 * @return {string}
 */
export function getButtonIcon(status) {
  switch (status) {
    case STATUS.DISABLED:
      return getUrl('files/project/misc/sync-grey.svg');
    case STATUS.ONGOING:
      return getUrl('files/project/misc/sync-black.svg'); // +css animation to spin it
    case STATUS.FAILED:
      return getUrl('files/project/misc/sync-red.svg');
    case STATUS.SUCCESS:
      return getUrl('files/project/misc/sync-green.svg');
    default:
      console.error(`Unexpected synchro favorites status: ${status}`);
  }
}

export function downloadFavorites(favorites) {
  if (!favorites || Object.keys(favorites).length === 0) return;
  const labels = getLabels();
  const today = moment().format('DD/MM/YYYY HH:mm');
  const documentTitle = `${labels.favorites.title}_${moment().format('DD_MM_YYYY')}`;
  const event_name = config.EVENT_NAME || '';

  let table = [];

  function addEmptyRow() {
    table.push({
      A: '',
      B: '',
      C: '',
    });
  }

  function addTitleRow(title) {
    table.push({
      A: title,
      B: '',
      C: '',
    });
  }

  addTitleRow(`${labels.favorites.yourFavs} ${event_name}`);
  addTitleRow(`Date: ${today}`);

  // clean incoming object
  const favoritesData = { ...favorites };
  Object.keys(favorites).forEach((dataType) => {
    if (favorites[dataType] && favorites[dataType].length === 0) {
      delete favoritesData[dataType];
    }
  });

  if (favoritesData[DATA_TYPE_EXHIBITORS] && favoritesData[DATA_TYPE_EXHIBITORS].length > 0) {
    addEmptyRow();
    addTitleRow(labels.data[DATA_TYPE_EXHIBITORS].plural);

    const exhData = favoritesData[DATA_TYPE_EXHIBITORS].map((exhibitorId) => {
      const item = Query.get(exhibitorId, DATA_TYPE_EXHIBITORS, ['places']);
        const stands =
        item && item.references && item.references.places && item.references.places.length > 0
          ? item.references.places.map((place) => place.label).join(',')
          : '';
        return {
          A: item ? item.title : '',
          B: stands ? stands : '',
          C: '',
        };
    });
    table = table.concat(exhData);
  }

  if (favoritesData[DATA_TYPE_EVENTS] && favoritesData[DATA_TYPE_EVENTS].length > 0) {
    addEmptyRow();
    addTitleRow(labels.data[DATA_TYPE_EVENTS].plural);

    const evtData = favoritesData[DATA_TYPE_EVENTS].map((eventId) => {
      const item = Query.get(eventId, DATA_TYPE_EVENTS, ['places']);
      const startDate = moment(item.start_date).format('DD/MM/YYYY');
      const stands =
        item.references && item.references.places && item.references.places.length > 0
          ? item.references.places.map((place) => place.label).join(',')
          : '';
      return {
        A: item ? item.title : '',
        B: item ? `${startDate} ${item.start_time}` : '',
        C: stands ? stands : '',
      };
    });

    table = table.concat(evtData);
  }

  const { events, exhibitors, ...other } = favoritesData;

  if (Object.keys(other).length > 0) {
    addEmptyRow();
    addTitleRow(labels.favorites.other);

    Object.keys(other).forEach((dataType) => {
      addEmptyRow();
      addTitleRow(labels.data[dataType].plural);

      const items = other[dataType].map((itemId) => {
        const item = Query.get(itemId, dataType);
          return {
            A: item ? item.title : '',
            B: '',
            C: '',
          }
      });
      table = table.concat(items);
    });
  }

  const ws_name = 'test';
  const wb = { SheetNames: [], Sheets: {} };
  const ws = utils.json_to_sheet(table, { skipHeader: true });
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;
  const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${documentTitle}.xlsx`);
}

// Ability to manually trigger `synchronize` in DEV env
if (config.ENV === 'dev') {
  global.synchronize = synchronize;
}
