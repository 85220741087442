import React from 'react';
import PropTypes from 'prop-types';

class FacebookPost extends React.PureComponent {
  state = {
    isSdkLoaded: false,
    width: 0,
  };

  componentDidMount() {
    this.setState({
      width: window.innerWidth,
    });
    if (document.getElementById('facebook-jssdk')) {
      this.sdkLoaded();
      return;
    }
    this.setFbAsyncInit();
    this.loadSdkAsynchronously();
  }

  componentDidUpdate(prevProps) {
    if (this.state.isSdkLoaded && window.FB) {
      window.FB.XFBML.parse(window.document.getElementById(this.getPostId()));
    }
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  setFbAsyncInit() {
    const { appId } = this.props;
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: appId || '286745252020603',
        status: false, // check login status
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse XFBML
        version: 'v11.0',
      });
    };
  }

  loadSdkAsynchronously() {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/fr_FR/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  getPostId = () => {
    const { href } = this.props;

    const cleanbedHref = href.endsWith('/') ? href.slice(0, -1) : href;
    const uriParts = cleanbedHref.split('/');
    return uriParts[uriParts.length - 1];
  };

  render() {
    const { href } = this.props;
    const { width } = this.state;
    return (
      <div id={this.getPostId()} className="media-facebook-post">
        <div
          className="fb-post"
          data-width={width <= 500 ? width : 500}
          data-href={href}
          data-show-text="false"
        />
      </div>
    );
  }
}

FacebookPost.propTypes = {
  href: PropTypes.string.isRequired,
  appId: PropTypes.string,
};

export default FacebookPost;
