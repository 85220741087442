import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_GOLDENSUMMIT } from 'app-customs/config/dataConfig';
import { GOLDENSUMMIT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import Description from 'src/components/fiche/Description';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';
import DetailSocial from 'src/components/detail-social/DetailSocial';
import PhoneRow from 'src/components/fiche/PhoneRow';
import EmailRow from 'src/components/fiche/EmailRow';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './GoldenSummitPage.scss';

class GoldenSummitContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_GOLDENSUMMIT,
      this.props.isFavorite
    );
  };

  render() {
    let lump = this.props.item.lump;

    const title = this.props.item.title,
      logoUrl = this.props.item.photo_file_name,
      description = this.props.item.description;
    const hasAddress =
        this.props.item.address_main ||
        this.props.item.postal_code ||
        this.props.item.city ||
        this.props.item.references.country,
      hasSocial = lump.social && (lump.social.tw || lump.social.fb || lump.social.ln || lump.social.pt || lump.social.ig || lump.social.yt || lump.social.tk || lump.social.wb),
      hasContactsBlock =
        (lump.contacts && lump.contacts.length > 0) ||
        this.props.item.phone_main ||
        this.props.item.email_main ||
        this.props.item.website_main ||
        hasAddress ||
        hasSocial ||
        (this.props.appointmentRequestStatus.isEnabled && lump.networking);

    return (
      <div className="fiche newproduct content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.pageTitle}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_GOLDENSUMMIT}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={GOLDENSUMMIT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              dataType={DATA_TYPE_GOLDENSUMMIT}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            {/* Exhibitor*/}
            <DetailExhibitor
              exhibitor={this.props.item.references.exhibitor}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {/* Market date */}
            {this.props.item.lump.date_market && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div className="color-grey-dark">
                      <span>{this.props.labels.goldensummit.launchDate}</span>
                    </div>
                  </div>
                  <div className="prop-right name-label">
                    <div className="color-grey-dark ">
                      <span>{this.props.item.lump.date_market}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* contacts */}
            {hasContactsBlock && (
              <div>
                <div className="fiche-separator">{this.props.labels.exhibitor.contacts}</div>

                {/* Appointment request - SEEMS LIKE DEAD CODE (networking v1)
                                { lump.networking && this.props.appointmentRequestStatus.isEnabled && !this.props.appointmentRequestStatus.date &&
                                    <CTAButton
                                        isEnabled={this.props.appointmentRequestStatus.ongoing !== true}
                                        label={this.props.labels.appointment.button}
                                        action={this.sendAppointmentRequest} />
                                }
                                { lump.networking && this.props.appointmentRequestStatus.isEnabled && this.props.appointmentRequestStatus.date &&
                                    <div className="appointment-request-sent">{
                                        this.props.labels.appointment.sent(
                                            formatDateAndTime(this.props.appointmentRequestStatus.date),
                                            this.props.appointmentRequestStatus.userEmail,
                                            this.props.appointmentRequestStatus.userPhone)
                                    }</div>
                                }
                                */}

                <DetailSocial data={lump.social} actions={this.props.actions} />

                {lump.contacts && lump.contacts.length > 0 && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon className="user-icon" />
                        </div>
                      </div>
                      <div className="prop-right">
                        {lump.contacts.map((entry, index) => (
                          <div key={index}>
                            <span
                              className="contactName"
                              dangerouslySetInnerHTML={{ __html: entry.n }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <PhoneRow phone={this.props.item.phone_main} />

                <WebsiteRow website={this.props.item.website_main} actions={this.props.actions} />

                <EmailRow email={this.props.item.email_main} />

                {/* address */}
                {hasAddress && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon
                            src={'icons-svg/fiche/university-solid.svg'}
                            className="university-icon fiche-icon"
                          />
                        </div>
                      </div>
                      <div className="prop-right">
                        {this.props.item.address_main && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.address_main }} />
                        )}
                        {this.props.item.postal_code && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.postal_code }} />
                        )}
                        {this.props.item.city && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.city }} />
                        )}
                        {this.props.item.references.country && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.item.references.country.title,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* end contacts */}
          </div>
        </div>
      </div>
    );
  }
}

GoldenSummitContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default GoldenSummitContent;
