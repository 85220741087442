import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  togglePageAfterNavigation,
  profileChanged,
  toggleLocationStatus,
  toggleMenu,
  updatePageState,
} from 'src/store/reducers/commons';

import { HELPERS_PAGE_KEY } from 'src/pages/pagesKeys';

const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(HELPERS_PAGE_KEY), {});

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);

    case LANG_CHANGED:
      return langChanged(state, action);

    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, HELPERS_PAGE_KEY);

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, HELPERS_PAGE_KEY);

    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, HELPERS_PAGE_KEY);

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, HELPERS_PAGE_KEY, action.pageKey);

    default:
      return state;
  }
};
