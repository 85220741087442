import {
  FETCH_SOCIAL_FEED2,
  SOCIAL_FEED_LOADED2,
  SET_SOCIAL_FEED_ERROR2,
  HAS_NAVIGATED,
  CONFIG_JSON_LOADED,
} from 'src/store/actionTypes';

import { showNotification, fetchSocialFeed2 } from 'src/store/actions';

import { fetch, persistence } from 'src/core/social-feed/SocialService';

import { get as getProfile } from 'src/core/Profile';

/* import { isSocialMediaBasicDisplay } from 'src/core/config-json/ConfigJsonManager'; */

import configJson from 'app-customs/config/configSocial';

import { MEDIAS_FEED_PAGE_KEY } from 'src/pages/pagesKeys';

const isSocialMediaBasicDisplay = () => configJson && configJson.basicDisplay === true;

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const state = getState();
    const mediasPageState = state[MEDIAS_FEED_PAGE_KEY];
    const labels = mediasPageState ? mediasPageState.labels : null;
    const socialMediaConfig =
      configJson && configJson[getProfile()] ? configJson[getProfile()] : null;
    const enabledPlatforms = socialMediaConfig ? Object.keys(socialMediaConfig) : [];
    const result = next(action);
    const disabledPlatforms = ['linkedin', 'tiktok', 'website'];
    disabledPlatforms.forEach((element) =>
      // Linked in not implemented
      {
        if (enabledPlatforms.indexOf(element) > -1) {
          const liIndex = enabledPlatforms.indexOf(element);
          enabledPlatforms.splice(liIndex, 1);
        }
      }
    );

    switch (action.type) {
      case CONFIG_JSON_LOADED:
        if (!isSocialMediaBasicDisplay()) {
          enabledPlatforms.forEach((platform) => {
            dispatch(fetchSocialFeed2({ type: platform, initial: true }));
          });
        }
        break;

      case HAS_NAVIGATED:
        if (!isSocialMediaBasicDisplay()) {
          if (action.pageKey === MEDIAS_FEED_PAGE_KEY) {
            enabledPlatforms.forEach((platform) => {
              dispatch(fetchSocialFeed2({ type: platform, initial: true }));
            });
          }
        }
        break;

      case FETCH_SOCIAL_FEED2:
        fetch(action.payload);
        break;

      case SOCIAL_FEED_LOADED2:
        persistence.set(action.payload);
        break;

      case SET_SOCIAL_FEED_ERROR2:
        if (labels) {
          dispatch(
            showNotification({
              message: labels.common.noNetworkTitle,
            })
          );
        }
        break;

      default:
    }
    return result;
  };
