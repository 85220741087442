import React from 'react';
import PropTypes from 'prop-types';

import { isAndroid, isCordovaContext } from 'src/core/util/browser';
import showPdf from 'src/core/pdf/pdfViewerAndroid';

import Url from 'src/components/url/Url';
import GenericIcon from 'src/components/generic-icon/GenericIcon';

import 'app-customs/config-scss/GenericIcon.scss';
import './DetailPdfs.scss';

function DetailPdfs({ data, actions, labels }) {
  if (!data) {
    return null;
  }

  const target = isCordovaContext() && !isAndroid() ? '_blank' : null;

  const callback = (url) => {
    actions.linkClicked(url);
    isAndroid() && showPdf(url);
  };

  return data?.map((pdf) => {
    return (
      <div className="free-row">
        <div className="prop-img">
          <div className="prop-left-pdf">
            <GenericIcon className="exp-data-link pdf-icon" />
          </div>
          <div className="prop-right  name-label padding-right">
            <div className="color-grey-dark">
              <Url
                callback={callback}
                href={pdf.url}
                disableClick={isAndroid()}
                openInInAppBrowser
                target={target}
              >
                {pdf.title || labels?.pdfs?.titleBtn}
              </Url>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

DetailPdfs.propTypes = {
  data: PropTypes.array,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default DetailPdfs;
