import React from 'react';
import PropTypes from 'prop-types';

class FacebookPage extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  containerRef = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    const { src } = this.props;
    const { width, height } = this.state;
    return nextProps.src !== src || nextState.width !== width || nextState.height !== height || window.innerWidth !== width || window.innerWidth !== height;
  }

  componentDidMount() {
    this.setState({
      width:
        (this.containerRef && this.containerRef.current && this.containerRef.current.clientWidth) ||
        window.innerWidth,
      height:
        (this.containerRef && this.containerRef.curren && this.containerRef.current.clientHeight) ||
        window.innerHeight,
    });
  }

  render() {
    const { src, appId } = this.props;
    const { width, height } = this.state;
    const source = encodeURIComponent(src);
    return (
      <div
        className="facebook-page-timeline"
        ref={(divElement) => {
          this.containerRef = divElement;
        }}
      >
        <iframe
          title="facebook-page"
          src={
            `https://www.facebook.com/plugins/page.php?href=${source}` +
            `&tabs=timeline` +
            `&width=${width <= 500 ? width : 500}` +
            `&height=${height}` +
            `&small_header=false` +
            `&adapt_container_width=true` +
            `&hide_cover=false` +
            `&show_facepile=true` +
            `&appId=${appId || '286745252020603'}`
          }
          width={width <= 500 ? width : 500}
          height={height}
          style={{
            border: 'none',
            overflow: 'hidden',
            width: width <= 500 ? width : 500,
            height: height,
          }}
          scrolling="yes"
          frameBorder="0"
          // allowTransparency
          allow="encrypted-media"
        />
      </div>
    );
  }
}

FacebookPage.propTypes = {
  src: PropTypes.string.isRequired,
  appId: PropTypes.string,
};

export default FacebookPage;
