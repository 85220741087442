import config from 'app-customs/config/config';
import { isAndroid } from 'src/core/util/browser';

const LOG_PREF = '[PdvViewerAndroid]';

const showPdf = (url, title, _options) => {
  var options = {
    headerColor: '#000000',
    showScroll: true,
    showShareButton: false,
    showCloseButton: true,
    swipeHorizontal: false,
  };
  if (isAndroid() && config.SHOW_PDF_ANDROID && config.SHOW_PDF_ANDROID.FEATURE_ENABLED) {
    window?.AndroidNativePdfViewer?.openPdfUrl(
      url,
      title || '',
      _options || options,
      function (success) {
        // success callback
        console.info(`${LOG_PREF}success ${success}`);
      },
      function (error) {
        // error callback
        console.error(`${LOG_PREF}error ${error}`);
      }
    );
  } else {
    if (!isAndroid()) {
      console.warn(`${LOG_PREF}FEATURES DISABLED: work only in android platform`);
    }
    console.warn(`${LOG_PREF}FEATURES DISABLED: TRY TO ACTIVATE IT IN CONFIG DATA`);
  }
};
export default showPdf;
