import React from 'react';
import PropTypes from 'prop-types';
import InstagramEmbed from 'react-instagram-embed';

class InstagramStory extends React.Component {
  render() {
    const { id } = this.props;

    let width = Number(document.documentElement.clientWidth - 30);

    if (width > 500) {
      width = 500;
    }
    
    return (
      <div className="media-instagram-post" id={id}>
        <InstagramEmbed
          url={id}
          clientAccessToken={'941470756472057|01c7c6a8971d1c95eaec0a007ecab718'}
          maxWidth={width}
          hideCaption={false}
          containerTagName='div'
          protocol="https:"
          injectScript
          onLoading={(onLoading) => {
            console.log('InstagramEmbed onLoading', onLoading);
          }}
          onSuccess={(err) => {
            //https://github.com/sugarshin/react-instagram-embed/issues/295
            console.error('InstagramEmbed onError', err);
          }}
          onFailure={(succ) => {
            console.info('InstagramEmbed onSuccess', succ);
          }}
          onAfterRender={() => {
            console.info('InstagramEmbed onAfterRender');
            /* const story = document.getElementById(id);
            const iframes = story.getElementsByTagName('div') || [];
            for (const ifr of iframes) {
              ifr.style.width = `${width}px`;
              ifr.style.maxWidth = `${width}px`;
            } */
          }}
          style={{
            width,
            maxWidth: width,
          }}
        />
      </div>
    );
  }
}

InstagramStory.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InstagramStory;
