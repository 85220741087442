import React from 'react';
import PropTypes from 'prop-types';

import DetailSocialRaw from './DetailSocialRaw';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const DetailSocial = ({ data, actions }) => {
  if (data && (data.tw || data.fb || data.ig || data.ln || data.yt || data.tk)) {
    return (
      <div className="free-row">
        <div className="prop-img">
          <div className="prop-left">
            <div className="fiche-contact-icon">
              <GenericIcon
                src="icons-svg/fiche/comments-solid.svg"
                className="comments-icon fiche-icon"
                style={{ fontSize: '20px' }}
              />
            </div>
          </div>
          <div className="prop-right social-icons-container">
            <DetailSocialRaw data={data} actions={actions} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

DetailSocial.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.object.isRequired,
};
export default DetailSocial;
