import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FacebookPost from './FacebookPost';
import FacebookPage from './FacebookPage';
import Tweet from './Tweet';
import InstagramStory from './InstagramStory';
import YoutubeVideo from './YoutubeVideo';
import Spring from './Spring';
import renderRefreshSpinner from './refreshSpinner';
import Url from '../../components/url/Url';

import { getUrl } from '../../core/data-and-assets/DataAssetsUtil';

const MAX = 80;
const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

class MediaTab extends Component {
  state = {
    displayIsRefreshing: false,
    displayIsLoadingMore: false,
    // pullToResfreshDisabled: !global.isCordovaContext,
    pullToResfreshDisabled: true,
    isRefreshing: false,
    isLoadingMore: false,
    y: 0,
    yRefreshing: 0,
    max: MAX,
    phase: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { data, platform } = this.props;

    if (nextProps.platform === 'facebook') {
      if (nextProps.platform !== platform) {
        return true;
      }
      return false;
    }

    const currentPosts =
      data[platform] && data[platform].posts.length > 0 ? JSON.stringify(data[platform].posts) : [];
    const nextPosts =
      nextProps.data[nextProps.platform] && nextProps.data[nextProps.platform].posts.length > 0
        ? JSON.stringify(nextProps.data[nextProps.platform].posts)
        : [];

    return (
      nextState.y !== this.state.y ||
      nextState.phase !== this.state.phase ||
      nextState.displayIsRefreshing !== this.state.displayIsRefreshing ||
      nextState.displayIsLoadingMore !== this.state.displayIsLoadingMore ||
      currentPosts !== nextPosts ||
      nextProps.data[nextProps.platform].initialFetch !== data[platform].initialFetch
    );
  }

  componentDidMount() {
    this._y = 0;
    this._scrollTop = 0;
    this._spring = new Spring(MAX, 10);
    this._spring.onUpdate = this.onSpringUpdate;
    this.onRefresh = this.onRefresh.bind(this);
    this.refresh = this.refresh.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  /******** PULL TO REFRESH FCT *********/

  onDown = (evt) => {
    const { phase } = this.state;
    if (this._willRefresh) return;
    if (phase === 'refreshed' || phase === 'refreshing') return;
    this._down = true;
    const ey = evt.nativeEvent.touches ? evt.nativeEvent.touches[0].pageY : evt.pageY;
    this._py = ey;
  };

  async onUp(evt) {
    const { phase } = this.state;
    if (phase === 'refreshed' || phase === 'refreshing') return;
    this._down = false;
    await this._refresh();
  }

  onMove = (evt) => {
    const { phase } = this.state;
    if (this._willRefresh || !this._down) return;
    if (phase === 'refreshed' || phase === 'refreshing') return;
    const ey = evt.nativeEvent.touches ? evt.nativeEvent.touches[0].pageY : evt.pageY;
    if (this._scrollTop <= 0) {
      this._y = this._y + ey - this._py;
      this._spring.endValue = this._y;
    }
    this._py = ey;
  };

  onSpringUpdate = (spring) => {
    const { max, yRefreshing, phase } = this.state;
    const y = spring.currentValue;
    this.setState({
      y,
      yRefreshing: this._willRefresh ? Math.max(y, yRefreshing) : y,
    });

    if (phase !== 'refreshed' && phase !== 'refreshing') {
      const newPhase = y >= max ? 'willRefresh' : '';
      if (phase !== newPhase) this.setState({ phase: newPhase });
    }
    if (phase === 'refreshed' && y === 0) {
      this.setState({ phase: '' });
    }
  };

  onRefresh() {
    const { platform, data, actions } = this.props;
    const posts = data[platform].posts;
    const sinceId = posts[0]._id;

    return new Promise((resolve, reject) => {
      actions.fetchSocialFeed2({
        sinceId,
        type: platform,
        callback: resolve,
      });
    });
  }

  async refresh() {
    this.setState({
      phase: 'willRefresh',
    });
    await sleep(0);
    await this._refresh();
  }

  async _refresh() {
    const { max, phase } = this.state;
    if (phase === 'willRefresh') {
      this._willRefresh = true;
      await this._spring.to(max);
      this._spring.pause();
      this._willRefresh = false;
      this.setState({
        phase: 'refreshing',
      });
      await this.onRefresh();
      await sleep(2000);

      this.setState({
        yRefreshing: 0,
        phase: 'refreshed',
      });
      this._spring.resume();
    }
    this._y = 0;
    this._spring.endValue = this._y;
  }

  /******** INFINITE SCROLL FCT *********/

  onScrollToTop = (complete) => {
    const { platform, data, actions } = this.props;
    const posts = data[platform].posts;
    const sinceId = posts[0]._id;

    actions.fetchSocialFeed2({
      sinceId,
      type: platform,
      callback: complete,
    });
  };

  onScrollToBottom = (complete) => {
    const { platform, data, actions } = this.props;
    const posts = data[platform].posts;
    const maxId = posts[posts.length - 1]._id;

    actions.fetchSocialFeed2({
      maxId,
      type: platform,
      callback: complete,
    });
  };

  viewDidScroll = (event) => {
    const { platform } = this.props;
    const dom = document.getElementById(`${platform}-tableView`);

    // consts for calculation
    const scrollviewOffsetY = dom.scrollTop;
    const scrollviewFrameHeight = dom.clientHeight;
    const scrollviewContentHeight = dom.scrollHeight;
    const sum = scrollviewOffsetY + scrollviewFrameHeight;

    // for pull to refresh
    this._scrollTop = scrollviewOffsetY;

    if (sum <= scrollviewFrameHeight) {
      if (this.state.isRefreshing || !this.state.pullToResfreshDisabled) {
        return;
      }
      this.setState({ isRefreshing: true });
      this.setState({ displayIsRefreshing: true });
      this.onScrollToTop(() => {
        setTimeout(() => {
          this.setState({ displayIsRefreshing: false });
          setTimeout(() => this.setState({ isRefreshing: false }), 500);
        }, 1500);
      });
    } else if (sum >= scrollviewContentHeight - 1) {
      if (this.state.isLoadingMore) {
        return;
      }

      this.setState({ isLoadingMore: true });
      this.setState({ displayIsLoadingMore: true });
      this.onScrollToBottom(() => {
        setTimeout(() => {
          this.setState({ displayIsLoadingMore: false });
          setTimeout(() => this.setState({ isLoadingMore: false }), 500);
        }, 1500);
      });
    }
  };

  renderLink = () => {
    const { actions, config } = this.props;
    return (
      <Url
        callback={actions.linkClicked}
        href={config.link}
        target="_system"
        className="media-button"
      >
        <div
          style={{
            backgroundImage: `url(${getUrl(config.logo)})`,
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            pointer: 'cursor',
          }}
        />
      </Url>
    );
  };

  render() {
    const { platform, data, config, height } = this.props;
    const { pullToResfreshDisabled, displayIsRefreshing, displayIsLoadingMore } = this.state;

    if (platform === 'facebook2' || (platform === 'facebook' && config.src)) {
      return (
        <div
          style={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <FacebookPage src={config.src} height={height} />
        </div>
      );
    } else if (config.link) {
      return (
        <div
          style={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {this.renderLink()}
        </div>
      );
    }

    const posts = data[platform].posts;
    const isLoading = data[platform].initialFetch;

    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div className={`fixed-spinner top ${displayIsRefreshing ? 'hover' : ''}`}>
          <div className={`spinner-wrapper ${displayIsRefreshing ? 'display' : ''}`}>
            <div className="infinit-table-spinner rotate" />
          </div>
        </div>

        <div className={`fixed-spinner bottom ${displayIsLoadingMore ? 'hover' : ''}`}>
          <div className={`spinner-wrapper ${displayIsLoadingMore ? 'display' : ''}`}>
            <div className="infinit-table-spinner rotate" />
          </div>
        </div>

        <div
          className="tableView"
          id={`${platform}-tableView`}
          onScroll={this.viewDidScroll}
          onMouseDown={pullToResfreshDisabled ? undefined : this.onDown.bind(this)}
          onMouseUp={pullToResfreshDisabled ? undefined : this.onUp.bind(this)}
          onMouseMove={pullToResfreshDisabled ? undefined : this.onMove.bind(this)}
          onTouchStart={pullToResfreshDisabled ? undefined : this.onDown.bind(this)}
          onTouchEnd={pullToResfreshDisabled ? undefined : this.onUp.bind(this)}
          onTouchMove={pullToResfreshDisabled ? undefined : this.onMove.bind(this)}
        >
          {renderRefreshSpinner(this.props, this.state)}

          <div className="platform-timeline" id={`${platform}-platform-timeline`}>
            {isLoading && <div className="infinit-table-spinner rotate initial" />}
            {platform === 'facebook' &&
              posts.map((post) => <FacebookPost key={post.postId} href={post.postId} />)}
            {platform === 'twitter' &&
              posts.map((post) => (
                <Tweet key={post.postId} id={post.postId} socialId={post.socialId} />
              ))}
            {platform === 'instagram' &&
              posts.map((post) => <InstagramStory key={post.postId} id={post.postId} />)}
            {platform === 'youtube' &&
              posts.map((post) => <YoutubeVideo key={post.postId} id={post.postId} />)}
          </div>
        </div>
      </div>
    );
  }
}

MediaTab.propTypes = {
  platform: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default MediaTab;
