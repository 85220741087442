// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import { MEDIAS_FEED_PAGE_KEY } from 'src/pages/pagesKeys';
import * as actions from 'src/store/actions';
import MediasPageContent from './MediasPageContent';
import MediasPageContentBasic from './MediasPageContentBasic';

import './MediasPage.scss';

const LOG_PREF = '[MediasPage] ';

class MediasPage extends Component {
  constructor(props) {
    super(props);
    this.pageKey = MEDIAS_FEED_PAGE_KEY;
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.medias.title;
  }

  componentDidMount() {
    this.setDocumentContext();
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  render() {
    console.log(`${LOG_PREF}render`);

    if (!this.props.isVisible) {
      return null;
    }

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
          hasSocialButtonInToolbar={this.props.socialButtonInToolbar}
        />

        {this.props.hasBasicDisplay ? (
          !this.props.mediaButtons ? null : (
            <MediasPageContentBasic
              mediaButtons={this.props.mediaButtons}
              // adConfig={ this.props.adConfig }
              actions={this.props.actions}
              labels={this.props.labels}
              isPageVisible={this.props.isVisible}
              isAppVisible={this.props.isAppVisible}
            />
          )
        ) : !this.props.socialMediaConfig ? null : (
          <MediasPageContent
            socialMediaConfig={this.props.socialMediaConfig}
            twitter={this.props.twitter}
            facebook={this.props.facebook}
            instagram={this.props.instagram}
            youtube={this.props.youtube}
            linkedin={this.props.linkedin}
            adConfig={this.props.adConfig}
            actions={this.props.actions}
            labels={this.props.labels}
            isPageVisible={this.props.isVisible}
            isAppVisible={this.props.isAppVisible}
            {...this.props.controlMediaPageContent}
          />
        )}
      </>
    );
  }
}

MediasPage.propTypes = {
  adConfig: PropTypes.object,
  socialMediaConfig: PropTypes.object.isRequired,
  twitter: PropTypes.object,
  facebook: PropTypes.object,
  instagram: PropTypes.object,
  youtube: PropTypes.object,
  linkedin: PropTypes.object,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
  socialButtonInToolbar: PropTypes.bool,
  isPageVisible: PropTypes.bool,
  isAppVisible: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[MEDIAS_FEED_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(MediasPage);
